.filter {
  width: 100%;
  padding: 10px 20px 20px;

  &__title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  &__wrapper {
    display: flex;
    max-width: 340px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: var(--gray);
    flex-direction: row;
    column-gap: 50px;
  }

  &__radio {
    margin-bottom: 0;
  }

  &__checkboxes {
    margin-top: 10px;
    flex-direction: unset;
    column-gap: 10px;

    &>button {
      margin: 0;
    }
  }

  &__checkbox {
    margin-bottom: 10px;
    width: 86px;
  }
}
