.list {
  position: absolute;
  top: 50px;
  left: 10px;
  z-index: 3;
  display: flex;
  flex-direction: column;
  background: var(--white);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  padding: 10px 0;

  &__item {
    display: flex;
    align-items: center;
    border: none;
    background-color: var(--white);
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    padding: 0 30px 0 20px;
    cursor: pointer;
    height: 30px;

    &:hover {
      background-color: rgba(223, 227, 232, 0.5);
      color: var(--blueHover);

      svg {
        fill: var(--blueHover);
      }
    }
  }
}
