.empty {
  font-size: 14px;
  line-height: 19px;
  color: var(--dark);
  padding: 20px 20px 0 26px;
}

.plusIcon {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  fill: var(--white);
}

.plusIconLine {
  width: 20px;
  height: 20px;
  margin-right: 6px;
  fill: var(--gray);
}

.deleteModal {
  padding: 10px 20px 0;

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: var(--dark);
    margin-bottom: 10px;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    margin-bottom: 10px;
  }
}

.exportIcon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  fill: var(--gray);
}

.exportButton {
  margin-right: 15px;

  &.panel {
    margin-left: 15px;
  }
}

.card {
  position: relative;
}

.createLineButton {
  margin-right: 15px;
}

.product-error {
  margin: 20px;
}