.login {
  background-color: var(--lightGray);
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  padding: 20px;

  &__wrap {
    max-width: 420px;
    width: 100%;
  }

  &__title {
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  &__container {
    border-radius: 3px;
    background-color: #fff;
    box-shadow: 0 0 0 1px #3f3f4426, 0 1px 3px 0 #3f3f4426;
    padding: 20px;
    max-width: 420px;
    width: 100%;
    margin-bottom: 30px;
  }
}
