.wrapper {

  &>div {
    width: 100%;
  }

  .dateInput {
    padding: 5px 12px;
    width: 100%;
    border-radius: 2px;
    min-height: 24px;
    height: 24px;
    border: 1px solid var(--lightGray);
    transition: 0.1s;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);

    &.large {
      height: 34px;
    }

    &:hover {
      border: 1px solid var(--blueHover);
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
    }

    &:focus {
      border: 1px solid var(--blueHover);
      filter: drop-shadow(0 0 4px rgba(81, 138, 186, 0.5));
      border-radius: 2px;
    }

    &.error {
      color: var(--error);
      border: 1px solid rgba(255, 51, 51, 0.5);
    }
  }

  .label {
    display: block;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: var(--gray);
  }

  .errorMessage {
    display: block;
    margin-top: 0;
    font-size: 9px;
    line-height: 12px;
    font-weight: 400;
    color: var(--error);
  }
}