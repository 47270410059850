.footer {
  padding: 20px 22px 20px 26px;
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  grid-column-gap: 40px;

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  &__count {
    flex: 0 0 auto;
    margin-right: 20px;
  }
}
