.radio {
  input {
    display: none;
  }

  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    color: var(--dark);
    font-size: 14px;
    line-height: 16px;
  }

  .circle {
    width: 16px;
    height: 16px;
    border: 1px solid var(--gray);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;

    .bullet {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: var(--blueHover);
      visibility: hidden;
    }

    &:hover {
      border: 1px solid var(--darkGray);
    }

    &.checked {
      border: 2px solid var(--blueHover);

      .bullet {
        visibility: visible;
      }
    }
  }

  &.disabled {
    pointer-events: none;
    cursor: none;

    .circle {
      border: 1px solid var(--lightGray);

      &.checked {
        border: 2px solid var(--lightGray);

        .bullet {
          background: var(--lightGray);
        }
      }
    }
  }
}
