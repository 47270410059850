.modal {
  padding: 0 20px 20px;
  font-size: 14px;
  line-height: 19px;

  .description {
    font-size: 14px;
    line-height: 19px;
    color: var(--gray);
    margin-bottom: 10px;
  }

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .checkboxes {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

.saveButton {
  width: 130px;
}