.container {
  width: 100%;

  .wrapper {
    padding: 10px 20px 20px;

    &:not(:first-of-type) {
      border-top: 1px solid var(--lightGray);
    }
  }

  .title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  .checkboxes {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 10px;

    &__deltaPrice {
      flex-direction: column;
    }
  }

  .checkbox {
    margin-bottom: 10px;
    width: 130px;

    &__deltaPrice {
      width: 100%;
    }
  }

  .range {
    padding: 0;
    margin: 10px 0 0;
  }
}

.border {
  border-bottom: 1px solid var(--lightGray);
}
