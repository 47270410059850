.wrapper {
  padding: 0 0 10px;
}

.suppliers {
  padding: 10px 10px 20px 20px;

  .content {
    height: 260px;
    overflow-x: hidden;
    width: 100%;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
      border: 2px solid white;
      border-radius: 10px;
      margin: 0 28px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--lightGray);
      border-radius: 20px;
    }
  }

  .checkbox {
    width: 268px !important;
  }
}