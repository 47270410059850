.collapse {
  background: var(--white);
  border: 1px solid var(--lightGray);
  box-sizing: border-box;
  border-radius: 10px;

  .header {
    display: flex;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    .wrapper {
      width: 100%;
      font-weight: 600;
      font-size: var(--font-size-medium);
      line-height: var(--line-height-medium);
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 20px;
    }

    .chevron {
      fill: none;

      path {
        stroke: var(--blue);
        fill: none;
      }

      &.active {
        transform: rotate(180deg);

        path {
          stroke: var(--gray);
        }
      }
    }
  }

  .content {
    display: none;
  }

  &.opened {
    .header {
      border-bottom: 1px solid var(--lightGray);
    }
    .content {
      display: block;
    }
  }
}

.section {
  border-bottom: 1px solid var(--lightGray);

  &:last-of-type {
    border-bottom: unset;
  }
}