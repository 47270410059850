.file {
  border: 1px solid var(--lightGray);
  border-radius: 2px;
  height: 60px;
  padding: 10px 0 10px 10px;
  display: flex;
  justify-content: space-between;

  &.error {
    border: 1px solid var(--error);
  }

  &__info {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
  }

  &__icon {
    width: 32px;
    height: 40px;
    margin-right: 10px;
  }

  &__title {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__closeIcon {
    height: 20px;
    width: 20px;
    fill: var(--gray);
  }

  &__errorMessage {
    color: var(--error);
    font-size: 14px;
  }
}
