.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 1vh;
  background-color: var(--darkGray);
  max-width: 80px;
  width: 100%;
  min-height: 100vh;
  flex: 0 0 auto;
  transition: 0.2s;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__wrapper {
    padding: 15px 10px 0 10px;

    &.staging {
      background-color: var(--yellow);

      button {
        border-bottom: unset;

        &:after {
          border-color: var(--superLightGray);
        }
      }

      p {
        color: var(--superLightGray);
      }
    }
  }

  &__button {
    border-width: 0 0 2px 0;
    border-bottom: 2px solid var(--white);
    background: none;
    outline: none;
    display: flex;
    flex: 0 0 auto;
    width: 100%;
    text-align: left;
    cursor: pointer;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 10px;
      right: 7px;
      width: 6px;
      height: 6px;
      border-right: 2px solid var(--lightGray);
      border-bottom: 2px solid var(--lightGray);
      transform: rotateZ(-45deg) translate(5px, 5px);
    }
  }

  &__title {
    display: flex;
    width: 100%;
    flex: 0 0 auto;
    font-size: 20px;
    line-height: 26px;
    color: var(--gray);
    white-space: nowrap;
    padding-bottom: 20px;
  }

  &__navigation {
    padding: 15px 0 0 10px;
  }

  &.active {
    max-width: 200px;

    .sidebar {
      &__button {
        &:after {
          top: 12px;
          transform: rotateZ(135deg) translate(-6px, -6px);
        }
      }
    }

    .navItem {
      &__text {
        display: flex;
      }
    }

    .footerLink {
      span:first-of-type {
        display: flex;
      }
    }
  }

  &__footer {
    margin-top: auto;
    padding: 0 0 0 10px;
    margin-bottom: 30px;
  }
}

.footerLink {
  color: var(--white) !important;

  span:first-of-type {
    display: none;
  }
}

.navBlock {
  .active ~ .navItem:not(:hover) {
    background-color: var(--dark);
  }

  .navItem:not(.parentNavItem) {
    padding-left: 46px;
  }

  .parentNavItem {
    &:hover {
      background-color: var(--lightDark);

      + .navChildrenWrapper.floating {
        display: block;
      }
    }

    .navItem__text {
      color: var(--gray);
      font-weight: normal !important;
    }
  }

  .chevron {
    margin-right: 4px;
    stroke: var(--gray);
    margin-left: auto;
    transition: transform 0.3s;

    &.expanded {
      transform: rotate(-180deg);
    }

    &.right {
      transform: rotate(-90deg);
    }
  }
}

.navChildrenWrapper {
  background-color: var(--darkGray);
  display: none;

  &:hover {
    display: block;
  }

  &.opened {
    display: block;

    .navItem {
      height: 36px;
    }
  }

  &.floating {
    position: absolute;
    right: 0;
    transform: translateX(100%);
    min-width: 200px;
    margin-top: -50px;
  }
}

.navItem {
  background-color: transparent;
  height: 50px;
  padding: 6px 10px;
  display: flex;
  align-items: center;
  text-decoration: none;
  flex: 0 0 auto;
  text-align: left;
  justify-content: left;

  &.active {
    background-color: var(--dark);

    .navItem {
      &__text {
        font-weight: 600;
      }

      &__icon {
        fill: var(--yellow);

        path {
          fill: var(--yellow);
        }
      }
    }

    &:hover {
      .navItem {
        &__text {
          font-weight: 600;
        }

        &__icon {
          fill: var(--yellow);

          path {
            fill: var(--yellow);
          }
        }
      }
    }
  }

  &:hover {
    background-color: var(--lightDark);

    .navItem {
      &__text {
        font-weight: 600;
      }

      &__icon {
        fill: var(--superLightGray);
      }
    }
  }

  &__icon {
    flex: 0 0 auto;
    margin-right: 16px;
    width: 20px;
    height: 20px;
    fill: var(--lightGray);
  }

  &__text {
    font-size: 14px;
    line-height: 19px;
    color: var(--lightGray);
    flex: 0 0 auto;
  }
}
