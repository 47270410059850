.modal {
  &__header {
    padding: 0 20px;
    border-bottom: 1px solid var(--lightGray);
  }

  &__infoIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__description {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--gray);
  }

  &__searchInput {
    margin-bottom: 10px;
  }

  &__searchIcon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    fill: var(--gray);
  }

  &__selected {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__content {
    max-height: 703px;
    overflow: auto;
  }

  &__tableContent {
    height: 400px;
    margin-right: 10px;
  }
}
