.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  &__prev,
  &__next {
    display: block;
    cursor: pointer;
    border-radius: 2px;
    border: 1px solid var(--lightGray);
    min-width: 32px;
    height: 32px;
    margin: 0 3px;
    padding: 5px 10px;
    position: relative;
    transition: 0.32s;
    color: var(--gray);
    background-color: var(--white);

    &:after {
      content: "";
      display: block;
      width: 7px;
      height: 7px;
      position: absolute;
      top: 10px;
      transform: rotate(45deg);
      transition: 0.32s;
    }

    &:hover {
      background: var(--black);
      border-color: var(--black);

      &:after {
        border-color: var(--darkGray);
      }
    }
  }

  &__prev {
    &:after {
      border-bottom: 2px solid var(--gray);
      border-left: 2px solid var(--gray);
      left: 13px;
    }

    &:disabled {
      pointer-events: none;
    }
  }

  &__next {
    &:after {
      border-top: 2px solid var(--gray);
      border-right: 2px solid var(--gray);
      right: 12px;
    }

    &:disabled {
      pointer-events: none;
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin: 0 3px;
    transition: 0.16s;
    cursor: pointer;
    font-size: 14px;
    line-height: 21px;
    border-radius: 2px;
    border: 1px solid var(--lightGray);
    background-color: var(--white);

    &.active {
      color: var(--blue);
      border-color: var(--blue);
    }

    &:disabled {
      pointer-events: none;
    }
  }
}
