.header {
  display: flex;
  align-items: center;
  margin-bottom: 26px;

  &__link {
    display: flex;
    align-items: center;
    color: var(--blue);
    font-size: 14px;
    line-height: 19px;
    text-decoration: none;
  }

  &__linkText {
    width: 39px;
    height: 17px;
    font-size: 13px;
    line-height: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #6473cc 0%, #4f5cb3 100%);
    border-radius: 4px;
    color: var(--white);
    margin-left: 4px;
  }
}
