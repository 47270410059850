.modal {
  padding: 0 20px;

  .wrapper {
    margin: 20px 0 0 0;

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
    }

    &__content {
      margin: 20px 0 0 0;
      display: flex;
      align-items: flex-end;
    }

    &__select {
      width: 100%;
    }

    &__label {
      display: flex;
      align-items: center;
      font-size: 14px;
      line-height: 19px;
      color: var(--gray);
    }
  }
}
