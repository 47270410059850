.checkboxes {
  display: flex;
  flex-direction: column;
}

.checkbox {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  margin-bottom: 18px;

  &__field {
    display: inline-block;
    min-width: 16px;
    height: 16px;
    border: 1px solid var(--lightGray);
    box-sizing: border-box;
    border-radius: 2px;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      display: block;
      width: 8px;
      height: 4px;
      border-bottom: 2px solid red;
      border-left: 2px solid red;
      transform: rotateZ(-45deg);
      opacity: 0;
    }

    &.indeterminate {
      background-color: var(--blueHover);
      border-color: var(--blueHover);

      &:after {
        content: "";
        position: absolute;
        top: 2px;
        left: 2px;
        display: block;
        height: 4px;
        width: 9px;
        border-left: none;
        transform: none;
        border-color: var(--white);
        opacity: 1;
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    margin-left: 12px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &.active {
    .checkbox {
      &__field {
        background-color: var(--blueHover);
        border-color: var(--blueHover);

        &:after {
          border-color: var(--white);
          opacity: 1;
        }
      }
    }
  }

  &.marginOff {
    margin-bottom: 0;
  }

  &.disabled {
    opacity: 0.3; // TODO
    pointer-events: none;
  }

  &:hover {
    .checkbox {
      &__field {
        border-color: var(--blueHover);
      }
    }
  }
}
