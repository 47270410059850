.filterDate {
  width: 100%;
  padding: 10px 20px 20px;

  &__title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    border: 1px solid var(--lightGray);
    border-radius: 2px;
    height: 32px;
    max-width: 260px;
    width: 100%;
  }

  &__box {
    max-width: 110px;
    width: 100%;
  }

  &__field {
    border: none;
    padding: 0 10px;
    height: 30px;
    max-width: 100%;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);

    &::placeholder {
      font-size: 14px;
      line-height: 19px;
      color: var(--lightGray);
    }
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin: 0 0 6px 12px;
    position: relative;
  }
}
