.app {
  display: flex;
  min-height: 100vh;

  &__content {
    transition: 0.2s;
    padding: 30px 30px 30px 110px;
    width: 100%;
    background-color: var(--superLightGray);
    position: relative;

    &.active {
      padding: 30px 30px 30px 190px;
    }
  }
}