.actions {
  position: relative;
  height: 32px;
  min-height: 32px;
  display: flex;
  align-items: center;
  width: max-content;
  max-width: 100%;

  div {
    max-width: 100%;
    width: 100%;
  }

  &.active {
    .select {
      &__options {
        display: flex;
      }
    }
  }

  &.disabled {
    .select {
      &__button {
        background-color: var(--superLightGray);
        color: var(--gray);
        cursor: default;
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &__button {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    border: none;
    outline: none;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    cursor: pointer;
    background-color: transparent;
  }

  &__selectedValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__options {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    background-color: var(--white);
    padding: 6px 0;
    z-index: 2;
  }

  &__option {
    display: block;
    border: none;
    background-color: var(--white);
    padding: 8px 16px 8px 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    text-align: left;
    cursor: pointer;
    z-index: 1;
    width: 100%;

    &:hover {
      background-color: var(--superLightGray);
    }

    &:disabled {
      opacity: 0.4;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}
