.loader {
  padding: 20px;
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background: var(--white);
  display: inline-table;

  &__line {
    height: 94px;
    margin-bottom: 30px;
    max-width: 100%;
    border-radius: 4px;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f1f1f1;
    background: linear-gradient(to right, #f7f7f7 8%, #f1f1f1 18%, #f7f7f7 33%);
    background-size: 1200px 104px;
    position: relative;

    &.header {
      height: 30px;
      margin-bottom: 26px;
      max-width: 190px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
