.banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 17px 30px 20px;
  background-color: var(--white);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  transform: translateY(-100%);
  transition: 0.2s;
  z-index: 2;

  &.active {
    transform: translateY(0);
  }

  &__message {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1.4fr;
    grid-column-gap: 20px;
  }
}
