.pagination {
  width: 210px;
  position: sticky;
  left: 0;
  display: flex;
  align-items: center;

  &__label {
    margin: 0 20px;
  }

  &__button {
    padding: 0 !important;
    height: 20px !important;
    width: 20px;

    svg {
      stroke: var(--gray);
    }

    &:hover {
      svg {
        stroke: var(--blueHover);
      }
    }

    &:focus {
      svg {
        stroke: var(--blue);
      }
    }

    &:disabled,
    &[disabled] {
      svg {
        stroke: var(--lightGray);
      }
    }
  }
}
