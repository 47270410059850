.container {
  width: 100%;
  padding: 10px 20px 20px;

  .title {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  .providers {
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 10px;
    margin-bottom: 10px;
  }

  .checkbox {
    margin-bottom: 10px;
    width: 130px;
  }

  .range {
    padding: 0;
    margin: 0;
  }
}
