.button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border: 1px solid var(--lightGray);
  border-radius: 2px;
  color: var(--dark);
  cursor: pointer;
  transition: 0.1s;

  &.primary {
    background-color: var(--blue);
    color: var(--white);
    border: none;

    &:hover {
      background-color: var(--blueHover);
      color: var(--white);

      svg {
        fill: var(--white);

        path {
          fill: var(--white);
        }
      }
    }

    &:focus {
      background-color: var(--blueFocus);
      color: var(--white);

      svg {
        fill: var(--white);

        path {
          fill: var(--white);
        }
      }
    }
  }

  &:hover {
    border-color: var(--blueHover);
    color: var(--blueHover);

    svg {
      fill: var(--blueHover);

      path {
        fill: var(--blueHover);
      }
    }
  }

  &:focus {
    border-color: var(--blue);
    color: var(--blue);

    svg {
      fill: var(--blue);

      path {
        fill: var(--blue);
      }
    }
  }

  &.large {
    font-size: 16px;
    line-height: 21px;
    padding: 10px 15px;
    height: 34px;
  }

  &.medium {
    font-size: 14px;
    line-height: 19px;
    padding: 6px 15px;
    height: 32px;
  }

  &.disabled {
    border: 1px solid var(--lightGray);
    color: var(--gray);
    background-color: var(--superLightGray);
    pointer-events: none;

    svg {
      fill: var(--gray);

      path {
        fill: var(--gray);
      }
    }
  }

  &.transparent {
    border: none;
    background: none;
  }

  &.link {
    border: none;
    background: none;
    color: var(--blue);
    padding: 0;

    &:hover {
      color: var(--blueHover);
    }

    &:focus {
      color: var(--blueFocus);
    }
  }
}

.spinner {
  width: 18px;
  height: 18px;
  margin: 0 10px;

  &.default {
    stroke: var(--blue);
  }
}
