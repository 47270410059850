.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;

  &__icon {
    display: block;
    width: 42px;
    height: 42px;
    stroke: var(--blue);
  }
}
