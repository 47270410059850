.modal {
  padding: 0 20px;

  &__description {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    color: var(--gray);
  }

  &__content {
    max-height: 445px;
  }

  &__infoIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
}

.template {
  margin: 10px 0;

  &__title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }

  &__content {
    margin: 10px 0 20px 0;
    display: flex;
    align-items: flex-end;
  }

  &__downloadButton {
    margin: 0 20px 0 40px;
  }

  &__downloadIcon {
    fill: var(--white);
  }

  &__select {
    width: 100%;
  }
}

.saveButton {
  width: 130px;
}

.processingError {
  color: var(--error);
  font-size: 14px;
}

.validationErrors {
  margin-top: 10px;
  font-size: 14px;
  padding: 20px 16px 20px 20px;
  border: 1px solid var(--lightGray);
  border-radius: 2px;

  &__content {
    overflow-y: auto;
    max-height: 155px;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
      border: 2px solid white;
      border-radius: 10px;
      margin: 0 20px 0 24px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--lightGray);
      border-radius: 20px;
    }
  }
}
