.toggler {
  display: flex;

  &__wrapper {
    display: block;
  }

  &__checkbox {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__circle {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gray);
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 16px;
      width: 16px;
      left: 2px;
      bottom: 2px;
      background-color: var(--white);
      transition: 0.4s;
      border-radius: 50%;
    }
  }

  &.readOnly {
    pointer-events: none;
  }


  &.active {
    .toggler {
      &__circle {
        background-color: var(--blueHover);

        &:before {
          transform: translateX(20px);
        }
      }
    }

    &.disabled {
      .toggler {
        &__circle {
          background-color: rgba(81, 138, 186, 0.5);
        }
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .toggler {
      &__circle {
        background-color: var(--lightGray);
      }
    }
  }
}

.successIcon {
  width: 20px;
  height: 20px;
  fill: var(--blueHover);

  circle {
    stroke: var(--blueHover);
  }

  path {
    fill: var(--white);
  }
}

.dotsIcon {
  width: 20px;
  height: 20px;

  path {
    fill: var(--gray);
  }
}

.label {
  display: block;
  font-size: 14px;
  line-height: 19px;
  font-weight: 400;
  color: var(--gray);
  margin-bottom: 7px;
}

.description {
  margin-left: 10px;
}