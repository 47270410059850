.filters {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  max-width: 650px;
  width: 100%;
  height: 100%;
  transform: translateX(100%);
  transition: 0.3s;
  background-color: var(--white);
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  z-index: 3;
  overflow: auto;

  &.active {
    transform: translateX(0);
  }

  &__header {
    position: relative;
    padding: 20px 5px 20px 20px;
    border-bottom: 1px solid var(--lightGray);
    display: flex;
    justify-content: space-between;

    .closeButton {
      svg {
        width: 20px;
        height: 20px;

        path {
          fill: var(--gray);
        }
      }

      &:hover {
        path {
          fill: var(--blueHover);
        }
      }

      &:active {
        path {
          fill: var(--blueFocus);
        }
      }
    }
  }

  &__title {
    font-size: 28px;
    line-height: 34px;
    font-weight: 400;
    color: var(--dark);
  }

  &__content {
    padding: 10px 20px 30px;
  }

  &__actions {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    border: 1px solid var(--lightGray);
    box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);

    .leftBlock {
      display: flex;
      column-gap: 30px;

      button {
        width: 140px;
      }
    }

    .saveButton {
      padding: 10px !important;
    }
  }
}

.filter {
  margin-top: 10px;

  &__title {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    margin-bottom: 10px;
  }
}

.savedFilters {
  display: flex;
  column-gap: 20px;
  margin-bottom: 20px;

  .select {
    width: 340px;
  }

  .text {
    display: flex;
    flex-wrap: wrap;
    color: var(--gray);
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);

    span:first-of-type {
      font-weight: 600;
    }
  }
}

.selectOption {
  width: 310px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 19px;
  padding: 0 4px 0 10px;

  &:hover {
    color: var(--blueHover);
  }

  &:active {
    color: var(--blue);
  }

  .deleteButton {
    padding: 0 !important;
    height: 20px;

    &:hover {
      path {
        fill: none;
        stroke: var(--blueHover);
      }
    }

    &:focus {
      path {
        fill: none;
        stroke: var(--blue);
      }
    }

    &[disabled] {
      path {
        fill: none;
        stroke: var(--lightGray);
      }
    }
  }
}

.selectContent {
  padding: 10px 0 15px;
}

.filterSearch {
  padding: 0 20px 15px;

  .input {
    margin-bottom: 0 !important;
  }
}

.searchIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: var(--gray);
}

.selectLabel {
  display: flex;
  column-gap: 4px;
  align-items: center;
}

.badge {
  background-color: var(--yellow);
  width: 31px;
  height: 18px;
  color: var(--white);
  font-size: var(--font-size-small);
  line-height: var(--line-height-small);
  text-align: center;
  border-radius: 2px;
}

.modalOverlay {
  z-index: 201 !important;
}

.saveButtonModal {
  width: 129px;
}

.cancelButtonModal {
  width: 107px;
}

.modal {
  padding: 10px 20px 0;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: var(--dark);
    margin-bottom: 10px;
  }

  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    margin-bottom: 10px;
  }
}