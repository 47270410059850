.empty {
  font-size: 14px;
  line-height: 19px;
  color: var(--dark);
  padding: 20px 20px 0 26px;
}

.table {
  height: calc(100vh - 299px);
}

.card {
  position: relative;
}

