.categories {
  background-color: transparent;

  .title {
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    margin: 10px 0 0 20px;
  }

  &__mainBtn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 50px;
    border: none;
    outline: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    position: relative;
    cursor: pointer;
    background: none;

    &.child {
      padding: 0 20px;
    }

    &:after {
      content: "";
      position: absolute;
      top: 19px;
      right: 20px;
      transform: rotateZ(45deg);
      display: block;
      width: 6px;
      height: 6px;
      border-right: 2px solid var(--gray);
      border-bottom: 2px solid var(--gray);
    }

    &.active {
      &:after {
        transform: translateY(2px) rotateZ(-135deg);
      }
    }

    &:disabled {
      color: var(--gray);
      cursor: not-allowed;
      background-color: var(--superLightGray);

      &:after {
        transform: translateY(0) rotateZ(45deg);
      }
    }
  }

  &__content {
    transition: 0.3s;
  }

  &__childContent {
    padding: 0 20px;
  }

  .checkboxes {
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 10px;
  }

  .checkbox {
    margin-bottom: 10px;
    width: 177px;
  }
}
