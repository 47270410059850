.field {
  width: 100%;
  margin-bottom: 20px;

  &__label {
    display: block;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: var(--gray);
  }

  &__value {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    width: 100%;
    border-radius: 2px;
    color: var(--dark);
    outline: none;
    background-color: transparent;
    border: 1px solid var(--lightGray);

    &:hover {
      border: 1px solid var(--blueHover);
    }

    &:focus {
      border: 1px solid var(--blue);
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
    }

    &::placeholder {
      color: var(--lightGray);
      font-size: 14px;
      line-height: 19px;
    }
  }

  &__wrapper {
    position: relative;
    background-color: var(--white);
  }

  &__icon {
    position: absolute;
    top: 55%;
    left: 8px;
    transform: translateY(-50%);
  }

  &__suffix {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);

    &.hidden {
      display: none;
    }
  }

  &__error {
    margin-top: 0;
    font-size: 9px;
    line-height: 12px;
    font-weight: 400;
    color: var(--error);
  }

  &.error {
    .field {
      &__value {
        color: var(--error);
        border: 1px solid rgba(255, 51, 51, 0.5);
        filter: drop-shadow(0px 0px 4px rgba(255, 51, 51, 0.5));
      }
    }
  }

  &.mb0 {
    margin-bottom: 0;
  }

  &.withIcon {
    .field {
      &__value {
        padding-left: 36px;
      }
    }
  }

  &.withSuffix {
    .field {
      &__value {
        padding-right: 36px;
      }
    }
  }

  &.large {
    .field {
      &__value {
        height: 34px;
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  &.medium {
    .field {
      &__value {
        height: 24px;
        font-size: 14px;
        line-height: 19px;
      }
    }
  }

  &__disabled {
    background-color: var(--superLightGray);
    color: var(--gray);

    &:hover {
      border: 1px solid var(--lightGray);
    }

    &:focus {
      border: 1px solid var(--lightGray);
    }
  }
}

.closeButton {
  padding: 0 !important;

  &__icon {
    width: 16px;
    height: 16px;
    fill: var(--gray);
  }
}
