.select {
  position: relative;
  border: 1px solid var(--lightGray);
  height: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  background-color: var(--white);
  border-radius: 2px;
  width: max-content;
  max-width: 100%;

  div {
    max-width: 100%;
    width: 100%;
  }

  &:after {
    content: "";
    position: absolute;
    top: 6px;
    right: 8px;
    display: block;
    width: 6px;
    height: 6px;
    border-right: 2px solid var(--gray);
    border-bottom: 2px solid var(--gray);
    transform: rotateZ(45deg);
    pointer-events: none;
  }

  &__label {
    display: block;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: var(--gray);
  }

  &__error {
    margin-top: 0;
    margin-left: 13px;
    font-size: 9px;
    line-height: 12px;
    font-weight: 400;
    color: var(--error);
  }

  &.error {
    border: 1px solid rgba(255, 51, 51, 0.5);

    .select__button {
      color: var(--error);
    }
  }

  &:hover {
    border-color: var(--blueHover);
  }

  &.active {
    border-color: var(--blueHover);
    filter: drop-shadow(0px 0px 4px rgba(81, 138, 186, 0.5));

    .select {
      &__options {
        display: flex;
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .select {
      &__button {
        background-color: var(--superLightGray);
        color: var(--gray);
        cursor: default;
      }
    }
  }

  &.fullWidth {
    width: 100%;
  }

  &.large {
    height: 34px;
    min-height: 34px;

    &:after {
      top: 11px;
    }

    .select {
      &__button {
        height: 32px;
      }
    }
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 0 24px 0 10px;
    width: 100%;
    height: 22px;
    background-color: var(--white);
    border: none;
    border-radius: 2px;
    outline: none;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    cursor: pointer;
  }

  &__selectedValue {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__options {
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    background-color: var(--white);
    padding: 6px 0;
    z-index: 200;
    max-height: 50vh;
    overflow-y: auto !important;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
      border: 2px solid white;
      border-radius: 10px;
      margin: 0 20px 0 24px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--lightGray);
      border-radius: 20px;
    }
  }

  &__option {
    display: block;
    border: none;
    background-color: var(--white);
    padding: 8px 16px 8px 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    text-align: left;
    cursor: pointer;
    z-index: 1;
    width: 100%;

    &:hover {
      background-color: var(--superLightGray);
    }

    &.disabled {
      pointer-events: none;
      color: var(--gray);
    }
  }

  &.empty {
    button {
      background-color: var(--superLightGray);
    }

    &:after {
      border-color: var(--gray);
    }

    &:hover {
      border-color: var(--blueHover);

      button {
        background-color: var(--lightBlueHover);
        color: var(--blueHover);
      }

      &:after {
        border-color: var(--blueHover);
      }
    }

    &:active {
      border-color: var(--blue);

      button {
        background-color: var(--lightBlueHover);
        color: var(--blue);
      }

      &:after {
        border-color: var(--blue);
      }
    }
  }

  &.progress {
    border-color: var(--blueHover);

    button {
      background-color: var(--lightBlueHover);
    }

    &:after {
      border-color: var(--gray);
    }

    &:hover {
      border-color: var(--blueHover);

      button {
        background-color: var(--lightBlue);
        color: var(--blue);
      }

      &:after {
        border-color: var(--blue);
      }
    }

    &:active {
      border-color: var(--blueFocus);

      button {
        background-color: var(--lightBlue);
        color: var(--blueFocus);
      }

      &:after {
        border-color: var(--blueFocus);
      }
    }
  }

  &.success {
    border-color: var(--green);

    button {
      background-color: var(--lightGreen);
    }

    &:after {
      border-color: var(--gray);
    }

    &:hover {
      border-color: var(--green);

      button {
        background-color: var(--lightGreen);
        color: var(--green);
      }

      &:after {
        border-color: var(--green);
      }
    }

    &:active {
      border-color: var(--darkGreen);

      button {
        background-color: var(--lightGreen);
        color: var(--darkGreen);
      }

      &:after {
        border-color: var(--darkGreen);
      }
    }
  }
}

.wrapper {
  &.fullWidth {
    width: 100%;
  }
}
