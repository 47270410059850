.container {
  position: fixed;
  top: 35px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  left: 50%;
  transform: translate(-50%, 0);
  align-items: center;
}

.toast {
  border-radius: 2px;
  height: 60px;
  display: inline-flex;
  justify-content: space-between;
  box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
  background: var(--white);
  margin-bottom: 10px;

  &__content {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    padding: 10px;
  }

  &__message {
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
  }

  &__successIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  &__closeIcon {
    height: 20px;
    width: 20px;
    fill: var(--gray);
  }

  &__closeButton {
    padding: 6px !important;
  }

  &__errorIcon {
    width: 20px;
    height: 20px;
    margin-right: 10px;

    circle {
      stroke: var(--error);
    }
    rect {
      fill: var(--error);
    }
  }
}
