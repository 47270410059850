.table {
  width: 100%;
  border-collapse: collapse;
  position: relative;

  .content {
    overflow-x: auto;
    width: 100%;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
      border: 2px solid white;
      border-radius: 10px;
      margin: 0 28px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--lightGray);
      border-radius: 20px;
    }
  }

  .wrapper {
    max-width: 100%;
    height: 100%;
  }

  &.fixed {
    .row.header {
      position: sticky;
      top: 0;
      background: var(--white);
    }

    .rows {
      overflow-y: scroll;
      padding-right: 6px;
      margin-right: 14px;

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.06);
        border: 2px solid white;
        border-radius: 10px;
        margin: 10px 20px 10px 14px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: var(--lightGray);
        border-radius: 20px;
      }
    }
  }

  &.horizontalScroll {
    .wrapper {
      width: max-content;
      min-width: 100%;
      max-width: unset;
    }
  }

  .row {
    display: grid;
    width: 100%;
    border-bottom: 1px solid rgba(223, 227, 232, 0.5);
    align-items: center;
    height: 40px;

    &:hover {
      background-color: var(--lightBlueHover);
    }

    &.header {
      font-weight: 600;
      padding-right: 28px;
      background-color: var(--superLightGray) !important;
      border-bottom: 1px solid var(--lightGray);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      height: 32px;
      overflow-y: hidden;

      &:hover {
        background-color: var(--white);
      }

      .item {
        text-overflow: unset;
        white-space: unset;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &.dragging {
      background-color: var(--lightBlueHover);
    }
  }

  .item {
    padding: 4px 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:first-of-type {
      padding: 4px 10px 4px 20px;
    }
  }
}
