.uploader {
  height: 100px;
  width: 100%;
  border: 1px dashed var(--lightGray);
  border-radius: 2px;
  position: relative;

  input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
  }

  &.active {
    border: 1px dashed var(--blueHover);
  }

  &__dndBlock {
    width: 100%;
    height: 60px;
    border-bottom: 1px dashed var(--lightGray);
    align-items: center;
    justify-content: center;
    display: flex;
  }

  &__dndText {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: var(--lightGray);
  }

  &__uploadBlock {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
  }

  &__uploadButton {
    display: flex;
    align-items: flex-end;
    cursor: pointer;
    position: relative;
    z-index: 2;

    &:hover {
      border-color: var(--blueHover);
      color: var(--blueHover);

      svg {
        fill: var(--blueHover);
      }
    }

    &:focus {
      border-color: var(--blue);
      color: var(--blue);

      svg {
        fill: var(--blue);
      }
    }

    &.disabled {
      pointer-events: none;
      color: red;
    }
  }

  &__buttonText {
    line-height: 19px;
  }

  &__uploadIcon {
    width: 16px;
    height: 16px;
    fill: var(--gray);
    margin-right: 8px;
  }
}
