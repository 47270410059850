.toggler {
  text-align: center;

  &__wrapper {
    display: inline-flex;
  }

  &__checkbox {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    cursor: pointer;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  &__label {
    display: block;
    margin-bottom: 6px;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    color: var(--gray);
  }

  &.disabled {
    pointer-events: none;
  }
}
