.textValue {
  border: none;
  outline: none;
  background-color: transparent;
  max-width: 100%;
  width: 100%;
  text-align: left;
  font-size: 14px;
  line-height: 19px;
  color: var(--dark);
  overflow: hidden;

  &:disabled {
    pointer-events: none;
    color: var(--gray);

    .textValue__text {
      color: var(--gray);
      cursor: default;
      background-color: var(--superLightGray);
      border: 1px solid var(--lightGray);
    }
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
    color: var(--dark);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 24px;
    padding: 0 0 0 13px;
    width: 100%;
    border-radius: 2px;
    outline: none;
    background-color: transparent;
    margin: 0 0 1px 0;
    border: 1px solid var(--lightGray);

    &:hover {
      line-height: 22px;
      padding: 0 0 0 12px;
      margin: 1px 0 0 0;
      border: 1px solid var(--blueHover);
    }

    &.error {
      margin: 0;
      border: 1px solid var(--error);
    }
  }

  &__error {
    font-size: 9px;
    line-height: 12px;
    height: 12px;
    font-weight: 400;
    color: var(--error);
  }
}

.holdTogglerField {
  &:hover {
    svg {
      path {
        stroke: var(--gray);
        opacity: 1;
      }
    }
  }
  &:active {
    svg {
      circle {
        fill: var(--lightBlue);
      }
      path {
        stroke: var(--blue);
        opacity: 1;
      }
    }
  }

  &.active {
    &:hover {
      svg {
        path {
          stroke: var(--error);
        }
      }
    }
    &:active {
      svg {
        path {
          stroke: var(--blue);
        }
      }
    }
  }

  &.disabled {
    svg {
      circle {
        fill: var(--superLightGray);
      }
      path {
        stroke: var(--lightGray);
      }
    }
  }
}

.readOnlyValue {
  font-size: 14px;
  line-height: 26px;
  color: var(--dark);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.link {
  color: var(--blue);
  font-size: 14px;
  line-height: 19px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;

  &:hover {
    color: var(--blueHover);
  }
}