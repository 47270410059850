.container {
  padding: 10px 30px 10px 20px;
  border-bottom: 1px solid var(--lightGray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  border-radius: 2px 2px 0 0;
  position: absolute;
  z-index: 3;
  width: 100%;
  background-color: var(--white);

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .selected {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: var(--gray);
      margin-right: 10px;
    }

    .bulkActions {
      margin-left: 30px;
    }
  }
}

.button {

  svg {
    margin-left: 10px;

    path {
      fill: none !important;
      stroke: var(--gray);
    }
  }

  &:hover {
    path {
      stroke: var(--blueHover);
      fill: none !important;
    }
  }

  &:active {
    path {
      stroke: var(--blueFocus);
      fill: none !important;
    }
  }
}