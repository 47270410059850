.rangeFilter {
  width: 100%;
  padding: 0 20px;
  margin: 20px 0;

  &__title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  &__wrapper {
    display: flex;
    height: 24px;
    max-width: 340px;
    width: 100%;
    font-size: 14px;
    line-height: 19px;
    color: var(--gray);
  }

  &__fieldBlock {
    display: inline-flex;
    margin-right: 20px;
  }

  &__label {
    margin-right: 8px;
  }

  &__field {
    width: 130px;
    margin-bottom: unset;
  }
}
