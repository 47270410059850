.tag {
  display: flex;
  align-items: center;
  width: max-content;
  height: 24px;
  padding: 1px 2px 1px 6px;
  margin: 0 10px 10px 0;
  font-size: 14px;
  line-height: 19px;
  color: var(--blue);
  box-sizing: border-box;
  border-radius: 2px;
  background: rgba(81, 138, 186, 0.05);
  border: 1px solid rgba(81, 138, 186, 0.5);

  .btn {
    border: none;
    background: none;
    outline: none;
    margin-left: 10px;
    cursor: pointer;
  }

  .icon {
    display: block;
    width: 20px;
    height: 20px;
    fill: var(--gray);
  }
}