.actionList {
  width: 100%;
  height: 100%;
  z-index: 2;

  &__globalBg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 2;
  }

  &__icon {
    fill: var(--gray);
    width: 20px;
    margin-right: 6px;
    display: block;
  }
}
