.content {
  &__header {
    margin-bottom: 20px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__title {
    font-size: 28px;
    line-height: 24px;
    font-weight: 600;
    color: var(--dark);
  }

  &__headerActions {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}
