.modal {
  padding: 10px 20px 20px;
  font-size: 14px;
  line-height: 19px;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 20px;
  }

  .selects {
    display: flex;
    flex-wrap: wrap;
    row-gap: 30px;
  }
}

.saveButton {
  width: 130px;
}