.modal {
  &__header {
    padding: 0 20px 10px;
    border-bottom: 1px solid var(--lightGray);
  }

  &__description {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    color: var(--gray);
  }

  &__selected {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__content {
    max-height: 703px;
    overflow: auto;
  }

  &__tableContent {
    height: 350px;
  }

  &__wrapper {
    max-width: 620px !important;
  }
}

.dndButton {
  height: 30px !important;
  width: 30px;
  padding: 5px !important;
}

.dndIcon {
  fill: var(--gray);
  display: block;
  width: 20px;
  height: 20px;
}
