.table {
  position: relative;

  &__wrapper {
    overflow-x: auto;
    height: calc(100vh - 259px);

    &::-webkit-scrollbar {
      width: 12px;
      height: 8px;
    }

    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.06);
      border: 2px solid white;
      border-radius: 10px;
      margin: 0 20px 0 24px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--lightGray);
      border-radius: 20px;
    }
  }

  &__container {
    width: max-content;
    min-width: 100%;
  }

  &__row {
    display: flex;
    width: 100%;
    padding: 0 20px 0 0;
    height: 40px;
    max-height: 40px;
    align-items: center;
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    border-bottom: 1px solid var(--lightGray);

    &:last-child {
      border-bottom: 0;
    }

    &.header {
      font-weight: 600;
      position: sticky;
      top: 0;
      z-index: 2;

      &:hover {
        background-color: var(--white);

        .table {
          &__item {
            background-color: var(--white);
          }
        }
      }
    }

    &.create {
      background-color: rgba(81, 138, 186, 0.05);
    }

    &.disable {
      opacity: 0.3;
      pointer-events: none;
    }

    &.active {
      background-color: var(--lightBlueHover);

      .table {
        &__item {
          background-color: var(--lightBlueHover);
        }
      }
    }

    &:hover {
      background-color: var(--lightBlueHover);

      .table {
        &__item {
          background-color: var(--lightBlueHover);
        }
      }

      .actions {
        &__list {
          opacity: 1;
          pointer-events: all;
        }
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 20px 0 0;
    background-color: var(--white);

    &:first-child, &:nth-child(2) {
      position: sticky;
      z-index: 1;
    }

    &:first-child {
      left: 0;
    }

    &:nth-child(2) {
      left: 80px;
      border-right: 1px solid var(--lightGray);
      box-shadow: 1px 0 3px rgba(0, 0, 0, 0.05);
    }

    &:nth-child(3) {
      padding-left: 20px;
    }

    &.leftOffset {
      left: 120px;
    }
  }

  &__checkbox {
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 20px;
  }

  &__headerItem {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 20px 0 2px;
    background-color: var(--white);

    span {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-word;
    }

    &:first-child, &:nth-child(2) {
      position: sticky;
      z-index: 1;
      background-color: var(--white);
    }

    &:first-child {
      left: 0;
      padding-left: 24px;
    }

    &:nth-child(2) {
      left: 80px;
      border-right: 1px solid var(--lightGray);
      box-shadow: 1px 0 3px rgba(0, 0, 0, 0.05);
    }

    &:nth-child(3) {
      padding-left: 33px;
    }

    &.active {
      color: red;
      position: absolute;
    }

    &.leftOffset {
      left: 120px;
    }
  }

  &__headerCheckbox {
    padding-left: 20px;
    height: 100%;
    background-color: var(--white);
    display: flex;
    align-items: center;
  }

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    height: calc(100% - 8px);
    width: 50px;
    background: linear-gradient(270deg, #ffffff 3.03%, rgba(255, 255, 255, 0) 100%);
  }
}

.alignCenter {
  justify-content: center;

  & > * {
    text-align: center;
  }
}

.alignRight {
  justify-content: flex-end;

  & > * {
    text-align: right;
  }
}

.actions {
  width: 80px;
  display: flex;
  align-items: center;
  padding-right: 20px;

  &__list {
    display: flex;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    margin: 0 0 0 4px;

    .icon {
      fill: var(--gray);
      display: block;
      width: 20px;
    }
  }

  &__qty {
    margin-right: 10px;
    font-size: 14px;
    line-height: 19px;
    color: var(--dark);
  }

  &__variants {
    display: flex;
    align-items: center;
  }

  &__showVariants {
    position: relative;
    border: none;
    background: none;
    outline: none;
    width: 14px;
    height: 14px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      left: 50%;
      top: 40%;
      transform: translate(-50%, -50%) rotateZ(-45deg);
      display: block;
      width: 6px;
      height: 6px;
      border-left: 2px solid var(--gray);
      border-bottom: 2px solid var(--gray);
    }

    &.active {
      transform: rotateX(-180deg);
    }
  }
}

.checkbox {
  margin-bottom: 0 !important;
  padding: 0 15px 0 10px;
}

.headerActions {
  width: 80px;
}

.actions, .headerActions {
  &.big {
    width: 120px;
  }
}