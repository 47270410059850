.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  background-color: rgba(33, 43, 54, 0.15);

  &.active {
    opacity: 1;
    z-index: 100;
    pointer-events: all;
  }

  &__wrapper {
    background: #ffffff;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    width: 100%;
    max-height: calc(100vh - 100px);
    overflow: auto;

    &.large {
      max-width: 1264px;
    }

    &.medium {
      max-width: 720px;
    }

    &.small {
      max-width: 480px;
    }
  }

  &__title {
    padding: 20px;
    font-weight: 400;
    font-size: 28px;
    line-height: 34px;
    color: var(--dark);
    border-bottom: 1px solid var(--lightGray);
    margin-bottom: 10px;
    position: relative;
  }

  &__titleClose {
    position: absolute;
    padding: 10px;
    top: 15px;
    right: 5px;

    .icon {
      width: 20px;
      height: 20px;
      fill: var(--gray);
    }
  }

  &__actions {
    padding: 20px 20px 30px;
    display: flex;
  }

  &__save {
    margin-right: 20px;
  }
}
