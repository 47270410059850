.header {
  &__actions {
    padding: 10px 30px 10px 20px;
    border-bottom: 1px solid var(--lightGray);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.searchIcon,
.filtersIcon,
.slidersIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: var(--gray);
}

.filtersActiveIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: var(--gray);

  circle {
    fill: var(--blue);
  }
}

.filterButton {
  margin-left: 20px;

  &.active {
    color: var(--blue);
    border-color: var(--blue);

    &:hover {
      color: var(--blueHover);
      border-color: var(--blueHover);
    }
  }
}

.columnsButton {
  margin-left: 20px;
}

.sortButton {
  min-width: 160px;
  margin-left: 20px;
}
